import React from "react"
import AlMl from "../../assets/images/AIMl.svg"
import appleStore from "../../assets/images/apple-store.svg"
import Blub from "../../assets/images/Blub.svg"
import Chatbox from "../../assets/images/Chatbox.svg"
import Cloud from "../../assets/images/Cloud-our.svg"
import Collaboration from "../../assets/images/Collaboration.svg"
import Dialogue from "../../assets/images/Dialogue-box.svg"
import googlePlay from "../../assets/images/google-play.svg"
import Report from "../../assets/images/Report.svg"
import TeacherStudent from "../../assets/images/Teacher-with-student.svg"
import Uplift from "../../assets/images/Uplift.svg"
import admindashboard from "../../assets/sample/admin-dashboard.png"
import parentapp from "../../assets/sample/parent-app.mp4"
import studentapp from "../../assets/sample/student-app.mp4"
import teacherapp from "../../assets/sample/teacher-app.mp4"
import "../../assets/styles/program.scss"

const Program = () => {
  return (
    <>
      <div className="program-box">
        <div className="container d-lg-flex justify-content-between py-md-5">
          <div className="col-12 col-md-6 d-flex align-items-md-center">
            <div>
              <h1 className="fnt-22 fnt-sm-18 fnt-bold fnt-lg-bold fnt-md-45 fnt-mlg-35 text-center text-clr54 text-lg-start">
                Tell them and they forget. <br />
                Teach them and they remember. <br />
                Involve them and they learn.
              </h1>
              <div className="d-none d-lg-block mt-5 pt-3">
                <h5 className="fnt-lg-24 fnt-lg-medium text-clr54 mb-25 d-none d-md-block">
                  Download our App to check out our unmatched quality of
                  questions.
                </h5>
                <div className="d-flex justify-content-between justify-content-lg-start pt-1 pb-2 py-md-3">
                  <a
                    rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.actuateminds.beta"
                    target="_blank"
                    className="phone-btn fnt-md-20 fnt-500 bg-clr11 text-white mr-1"
                  >
                    <img src={googlePlay} alt="google-play" />
                    &nbsp; Google Play
                  </a>
                  <a
                    rel="noreferrer"
                    href="https://apps.apple.com/us/app/actuate-minds/id1586452790"
                    target="_blank"
                    className="phone-btn fnt-md-18 fnt-500 bg-clr11 text-white"
                  >
                    <img src={appleStore} alt="apple-store" /> &nbsp; App Store
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="d-flex justify-content-between mt-md-5 w-100">
              <div className="cloud">
                <h3 className="text-black fnt-baby cloud-center">
                  Why everyone sleeps in night?
                </h3>
                <img src={Cloud} alt="Cloud" className="w-100" />
              </div>
              <div className="chatbox">
                <h5 className="text-black fnt-baby chatbox-center w-75">
                  Children’s brain are full of questions and questions they ask
                  are not silly, if you can’t answer it.
                </h5>
                <img src={Chatbox} alt="Chatbox" className="w-100" />
              </div>
            </div>
            <div>
              <div className="teacher">
                <img
                  className="w-100"
                  src={TeacherStudent}
                  alt="TeacherStudent"
                />
              </div>
            </div>
          </div>
          <div className="d-lg-none pb-3">
            <h5 className="fnt-medium text-clr54 mt-3 text-center text-lg-start">
              Download our App to check out our unmatched quality of questions.
            </h5>
            <div className="d-flex justify-content-between justify-content-lg-start pt-1 pb-2 py-md-3">
              <a
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.actuateminds.beta"
                target="_blank"
                className="phone-btn fnt-md-18 fnt-500 bg-clr11 text-white mr-1"
              >
                <img src={googlePlay} alt="google-play" />
                &nbsp; Google Play
              </a>
              <a
                rel="noreferrer"
                href="https://apps.apple.com/us/app/actuate-minds/id1586452790"
                target="_blank"
                className="phone-btn fnt-md-18 fnt-500 bg-clr11 text-white"
              >
                <img src={appleStore} alt="apple-store" /> &nbsp; App Store
              </a>
            </div>
          </div>
        </div>
        <div className="objective-section bg-pink">
          <div className="container py-3">
            <h2 className="text-center fnt-24 fnt-700 fnt-lg-35 fnt-lg-bold text-clr54 py-md-5">
              Our objective
            </h2>
            <div className="d-lg-flex justify-content-around">
              <div className="dialogue">
                <div className="dialogue-center">
                  <div className="ms-2 ms-lg-0 dialogue-icon">
                    <div className="d-flex justify-content-center">
                      <img src={Blub} alt="Bulb" />
                    </div>
                    <p className="mt-md-3 fnt-18 fnt-700 fnt-lg-22 fnt-lg-500 text-clr54 text-lg-center ms-3 ms-lg-0 pb-0">
                      Analytical Thinking
                    </p>
                  </div>
                  <p className="mt-md-3 fnt-16 fnt-500 fnt-lg-18 fnt-lg-500 text-clr54 mb-0 text-lg-center">
                    Our Minds Actuators program enables every student to be
                    analytical and critical thinkers. Questions we provide are
                    very thought provoking.
                  </p>
                </div>
                <img
                  className="dialogue-image w-100"
                  src={Dialogue}
                  alt="Dialogue"
                />
              </div>

              <div className="dialogue">
                <div className="dialogue-center">
                  <div className="ms-2 ms-lg-0 dialogue-icon">
                    <div className="d-flex justify-content-center">
                      <img src={Uplift} alt="Uplift" />
                    </div>
                    <p className="mt-md-3 fnt-18 fnt-700 fnt-lg-22 fnt-lg-500 text-clr54 text-lg-center ms-3 ms-lg-0 pb-0">
                      Empower Teachers
                    </p>
                  </div>
                  <p className="mt-md-3 fnt-16 fnt-500 fnt-lg-18 fnt-lg-500 text-clr54 mb-0 text-lg-center">
                    Our clear report empowers teachers to focus on weak students
                    or complex topic which need more push.
                  </p>
                </div>
                <img
                  className="dialogue-image w-100"
                  src={Dialogue}
                  alt="Dialogue"
                />
              </div>
              <div className="dialogue">
                <div className="dialogue-center">
                  <div className="dialogue-icon">
                    <div className="d-flex justify-content-center">
                      <img
                        className="mb-md-3"
                        src={Collaboration}
                        alt="Collaboration"
                      />
                    </div>
                    <p className="mt-md-3 fnt-18 fnt-700 fnt-lg-22 fnt-lg-500 text-clr54 text-lg-center ms-3 ms-lg-0 pb-0">
                      Collaboration
                    </p>
                  </div>
                  <p className="mt-md-3 fnt-16 fnt-500 fnt-lg-18 fnt-lg-500 text-clr54 mb-0 text-lg-center">
                    We encourage collaboration between parent, teacher and
                    students using our chat feature.
                  </p>
                </div>
                <img
                  className="dialogue-image w-100"
                  src={Dialogue}
                  alt="Dialogue"
                />
              </div>
            </div>
            <div className="text-center d-lg-flex justify-content-center dial-bottom mx-lg-auto">
              <div className="dialogue">
                <div className="dialogue-center">
                  <div className="dialogue-icon">
                    <img className="mb-md-3" src={AlMl} alt="AlMl" />
                    <p className="mt-md-3 fnt-18 fnt-700 fnt-lg-22 fnt-lg-500 text-clr54 text-lg-center ms-3 ms-lg-0">
                      AI & ML
                    </p>
                  </div>
                  <p className="mt-md-3 fnt-16 fnt-500 fnt-lg-18 fnt-lg-500 text-clr54 mb-0 text-start text-lg-center">
                    Using Artificial Intelligence and Machine learning we create
                    a unique profile for each student and study their learning
                    journey, which enable us to solve more personalised
                    problems.
                  </p>
                </div>
                <img
                  className="dialogue-image w-100"
                  src={Dialogue}
                  alt="Dialogue"
                />
              </div>
              <div className="dialogue">
                <div className="dialogue-center">
                  <div className="dialogue-icon">
                    <img className="mb-md-3" src={Report} alt="Report" />
                    <p className="mt-md-3 fnt-18 fnt-700 fnt-lg-22 fnt-lg-500 text-clr54 text-lg-center ms-3 ms-lg-0">
                      Teacher report
                    </p>
                  </div>
                  <p className="mt-md-3  fnt-16 fnt-500 fnt-lg-18 fnt-lg-500 text-clr54 mb-0 text-start text-lg-center">
                    We study improvement report for each teachers and generate
                    proper report for School admins.
                  </p>
                </div>
                <img
                  className="dialogue-image w-100"
                  src={Dialogue}
                  alt="Dialogue"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 className="text-center mt-3">
        Checkout the features we provide to our education partners.
      </h2>
      <div className="stuenet-sec">
        <div className="container d-lg-flex justify-content-between py-3 py-md-5">
          <div className="col-12 col-md-6">
            <h2 className="fnt-md-40 fnt-md-700 text-clr54 py-md-5 text-center text-lg-start">
              Students
            </h2>
            <video
              playsInline
              autobuffer="true"
              className="my-3 mx-auto d-flex d-lg-none"
              autoPlay
              loop
              muted
            >
              <source src={studentapp} type="video/mp4" />
            </video>
            <ul className="fnt-lg-20 fnt-md-500 text-clr54">
              <li>
                Each chapter is a journey of thought provoking questions aimed
                to fulfil the chapter's learning objectives.
              </li>
              <li>
                Multiple Assessments to probe on the thorough understanding of
                concepts.
              </li>
              <li>
                Personalised follow-up questions based on the analysis of
                previous assessments.
              </li>
              <li>
                Question of the day: an engaging question aimed towards fueling
                the curiosity of of the student and driving engaging
                conversations around the topic with peers and teachers.
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6 d-lg-flex justify-content-center d-none d-lg-block">
            <video autobuffer="true" playsInline autoPlay loop muted>
              <source src={studentapp} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className="parents-sec bg-gray">
        <div className="container d-lg-flex justify-content-between py-3 py-md-5">
          <div className="col-12 col-md-6 d-lg-flex justify-content-center d-none d-lg-block">
            <video autobuffer="true" playsInline autoPlay loop muted>
              <source src={parentapp} type="video/mp4" />
            </video>
          </div>
          <div className="col-12 col-md-6">
            <h2 className="fnt-md-40 fnt-md-700 text-clr54 py-md-5 text-center text-lg-start">
              Parents
            </h2>
            <video
              autobuffer="true"
              playsInline
              className="my-3 mx-auto d-flex d-lg-none"
              autoPlay
              loop
              muted
            >
              <source src={parentapp} type="video/mp4" />
            </video>
            <ul className="fnt-lg-20 fnt-md-500 text-clr54">
              <li>
                Elaborated report of the child's performance and use of advanced
                analytics to accurately identify areas of improvement.
              </li>
              <li>
                Two way communication with teachers via Chat to enhance feedback
                on the a child's growth.
              </li>
              <li>Feedback to school and teacher on regular intervals.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="teacher-sec">
        <div className="container d-lg-flex justify-content-between py-3 py-md-5">
          <div className="col-12 col-md-6">
            <h2 className="fnt-md-40 fnt-md-700 text-clr54 py-md-5 text-center text-lg-start">
              Teachers
            </h2>
            <video
              autobuffer="true"
              playsInline
              className="my-3 mx-auto d-flex d-lg-none"
              autoPlay
              loop
              muted
            >
              <source src={teacherapp} type="video/mp4" />
            </video>
            <ul className="fnt-lg-20 fnt-md-500 text-clr54">
              <li>
                Using advanced analytics to provide detailed chapter-wise report
                that highlights areas to focus for each class.
              </li>
              <li>
                Detailed report that highlights key areas of improvement for
                every student.
              </li>
              <li>Hints for all questions, to help drive the conversation.</li>
              <li>Easily communicate with parents via in-built Chat.</li>
            </ul>
          </div>
          <div className="col-12 col-md-6 d-lg-flex justify-content-center d-none d-lg-block">
            <video
              autobuffer="true"
              playsInline
              className="videoTag"
              autoPlay
              loop
              muted
            >
              <source src={teacherapp} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className="admin-sec bg-gray">
        <div className="container d-lg-flex justify-content-between py-3 py-md-5">
          <div className="col-12 col-md-7 d-lg-flex justify-content-center d-none d-lg-block">
            <img
              className="admin-dash"
              src={admindashboard}
              alt="school-admin"
            />
          </div>
          <div className="col-12 col-md-5">
            <h2 className="fnt-md-40 fnt-md-700 text-clr54 py-md-5 text-center text-lg-start">
              School Admin
            </h2>
            <img
              className="admin-dash my-3 mx-auto d-flex d-lg-none"
              src={admindashboard}
              alt="school-admin"
            />
            <ul className="fnt-lg-20 fnt-md-500 text-clr54">
              <li>
                Detailed performance reports for all the teachers and their
                classes.
              </li>
              <li>
                Parent satisfaction reports from the feedback we collect at
                regular intervals.
              </li>
              <li>
                Manage your teachers: You can reorganise who teaches which
                subject and in which class.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Program
