import React from "react"
import ActuateMinds from "../components/actuate-minds/actuate-minds"
import Layout from "../components/layout/layout"
import Seo from "../components/layout/seo"
import Program from "../components/program/program"

export default function OurProgram() {
  return (
    <Layout active={"our-program"}>
      <Seo
        title="Key insights to our program"
        description="We have unique helpful features for students, parents, teachers and school admins who are involved in the educational journey of kids."
      />
      <Program />
      <ActuateMinds />
    </Layout>
  )
}
